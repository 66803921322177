import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import facebbok from "../assets/images/icFacebook.png";
import twitter from "../assets/images/icTwitter.png";
import instagram from "../assets/images/icInstagram.png";
import Helmet from "react-helmet";

const Footer = () => {
  return (
    <>
      <hr className="customhr"></hr>
      <footer className="customfooter pt-5">
        <Container
          fluid
          className="containerFooter justify-content-between d-flex flex-wrap"
        >

          <div className="col-12 col-md-12 col-lg-3">
            <NavLink to={"/"}><img src={logo} alt='NFT Creator4all logo' /></NavLink>
            <p className="font16Gray mt-3">
            NFT Creator4All offers a bespoke white-label NFT art generator for enthusiasts. Artists can effortlessly launch and customize the NFT Generator on their own server, ensuring a seamless experience when creating NFT art collections.
            </p>
          </div>

          <div className="col-12 col-md-12 col-lg-auto">
            <ul className="customnavlist" >
              <li>
                <NavLink to={"/"}>Home</NavLink>
              </li>
              <li>
                <NavLink to={"/Contact"}>Contact Us</NavLink>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-12 col-lg-auto">
            <div className="mb-2 customftmail">andy@nftcreator4all.com</div>
            <div className="d-flex mb-3 align-items-center justify-content-center">
            {/* <NavLink className="me-3 customcircle"><img height='15px' width='8px'src={facebbok}/></NavLink>
            <NavLink className='me-3 customcircle'><img height='12 px' width='13px' src={twitter}/></NavLink>
            <NavLink className='customcircle'><img height='15 px' width='15px' src={instagram}/></NavLink> */}
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-3">
            <p className="font16Gray">
              Subscribe right here to access exclusive features
              and exciting content
              to create your unique style or white label solution !!
            </p>
            <div
              className=""
              id="BiginWebToEntityFormDiv267113000000727096"
            >
              <form
                id="BiginWebToContactForm267113000000727096"
                name="BiginWebToContactForm267113000000727096"
                method="POST"
                enctype="multipart/form-data"
                onSubmit='javascript:document.charset="UTF-8"; returnvalidateForm267113000000727096()'
                accept-charset="UTF-8"
              >
                <input
                  type="text"
                  style={{ display: "none" }}
                  name="xnQsjsdp"
                  value="3fef1381d1076883454f9b8f5f77f1082ac431ee9953419b387a64de1fb423bc"
                />
                <input type="hidden" name="zc_gad" id="zc_gad" value="" />
                <input
                  type="text"
                  style={{ display: "none" }}
                  name="xmIwtLD"
                  value="e9cfc25d3a0c3f38a426576bf61dd1de13b6f6f5cbe0e1dda4f5a84d09657ece"
                />
                <input
                  type="text"
                  style={{ display: "none" }}
                  name="actionType"
                  value="Q29udGFjdHM="
                />
                <input
                  type="text"
                  style={{ display: "none" }}
                  name="returnURL"
                  value="https://nftcreator4all.com"
                />

                <div id="elementDiv267113000000727096" className="d-flex flex-wrap ">
                  <div className=" me-2">
                    <div className=" ">
                      <input
                        className="custommail w-100 mb-3"
                        name="Email"
                        type="text"
                        maxlength="80"
                        
                        placeholder="Enter Your Email"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className=""></div>
                    <div className="">
                      <input
                        className="btnPrimary sm btn btn-primary"
                        id="formsubmit267113000000727096"
                        type="submit"
                        value="Subscribe Now"
                      />
                    </div>
                  </div>
                </div>
                {/* <Helmet>
                  <script src="../assets/js/subscribe.js"></script>
                </Helmet>
                <Helmet>
                  <script
                    id="wf_script"
                    src="../assets/js/WebformScriptServlet_scribe.js"
                  ></script>
                </Helmet> */}
              </form>
            </div>
          </div>

        </Container>
      </footer>
    </>
  );
};

export default Footer;
