import React, { Component } from 'react';
import { Container, Nav, Navbar, Button, } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';

// class Header extends Component {
function Header() {

    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.scrollY > 100) {
                document
                    .querySelector('.headerCustom')
                    .classList.add('scrolled');
            } else {
                document
                    .querySelector('.headerCustom')
                    .classList.remove('scrolled');
            }
        });
    }, []);

        

        return (
            <Navbar expand="lg" className='headerCustom' fixed="top">
                <Container fluid className='containerHeader'>
                    <Navbar.Brand href="/"><img src={logo} className="img-fluid" alt='NFT Creator4all logo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto align-items-center">
                            <NavLink to={"/"}>Home</NavLink>
                            {/* <NavLink to={"/a"}>Products</NavLink> */}
                            {/* <NavLink to={"/a"}>Resources</NavLink> */}
                            {/* <NavLink to={"/a"}>Pricing</NavLink> */}
                            <NavLink to={"/Contact"}>Contact Us</NavLink>
                            <NavLink target="_blank" to={"https://calendly.com/andy-nft"}>
                                <Button variant="" className="btnPrimary sm">Book a Demo</Button>
                            </NavLink>
                            {/* <Button className="btnPrimary" onClick={() => navigate('https://calendly.com/andy-nft')}>Book a Demo</Button> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
}

export default Header;