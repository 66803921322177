import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/scss/style.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
// import Services from "./pages/Services";
import Contact from "./pages/Contact";

function App() {
  return (
    <>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="AboutUs" element={<AboutUs />} />
        {/* <Route path="Services" element={<Services />} /> */}
        <Route path="Contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
