import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <>
      <Header />

      <Container fluid className="containerCustom">
        <section className="contactPage">
          <Row className="justify-content-between my-100 w-100">
            <Col
              md={12}
              lg={4}
              className="d-flex align-items-center mb-5 mb-lg-0"
            >
              <div>
                <h1 className="font70 mb-3">Contact Us</h1>
                {/* <p className='mb-3 font18Gray'>
                                    ThreeJSDevelopers is your trusted partner for ThreeJS development services.
                                    Get in touch with us today to discuss your project and how we can help bring your ideas to life.
                                </p> */}
                <h3 className="mt-5 font24Gray fontBold">Andy Convoy</h3>
                <div className="mb-2 font16Gray">
                  <span className="fontBold">Email ID</span>
                  <br />
                  andy@nftcreator4all.com
                </div>
                {/* <div className='mb-2 font16Gray'>
                                    <span className='fontBold'>Mobile No</span><br />+91-9429005987
                                </div> */}
              </div>
            </Col>
            <Col
              md={12}
              lg={6}
              className="d-flex align-items-center mb-5 mb-lg-0"
            >
              <div
                id="BiginWebToEntityFormDiv267113000000667005"
                style={{ width: "100%" }}
              >
                <div
                  id="BiginWebToEntityFormDiv267113000000667005"
                  style={{
                    margin: "2em auto",
                    padding: "15px",
                    color: "#fff",
                  }}
                >
                  <meta
                    httpEquiv="content-type"
                    content="text/html;charset=UTF-8"
                  />
                  {/* <form
                    id="BiginWebToContactForm267113000000667005"
                    name="BiginWebToContactForm267113000000667005"
                    method="POST"
                    encType="multipart/form-data"
                    onsubmit='javascript:document.charset="UTF-8"; returnvalidateForm267113000000667005()'
                    acceptCharset="UTF-8"
                    style={{ margin: 0 }}
                  >
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="xnQsjsdp"
                      defaultValue="3fef1381d1076883454f9b8f5f77f1082ac431ee9953419b387a64de1fb423bc"
                    />
                    <input
                      type="hidden"
                      name="zc_gad"
                      id="zc_gad"
                      defaultValue
                    />
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="xmIwtLD"
                      defaultValue="e9cfc25d3a0c3f38a426576bf61dd1de92903e8bf0f3ba5d18ab9b4f74235f51"
                    />
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="actionType"
                      defaultValue="Q29udGFjdHM="
                    />
                    <input
                      type="text"
                      style={{ display: "none" }}
                      name="returnURL"
                      defaultValue="https://nftcreator4all.com"
                    />
                    <div id="elementDiv267113000000667005" className="row">
                      <div className="col-md-12 col-lg-6 mb-3">
                        <div className="bgn-wf-field">
                          <input
                            placeholder="First Name"
                            name="First Name"
                            type="text"
                            maxLength={40}
                            defaultValue={""}
                            className="inputCustom w-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6 mb-3">
                        <div className="bgn-wf-field">
                          <input
                            placeholder="Last Name"
                            name="Last Name"
                            className="inputCustom w-100"
                            type="text"
                            maxLength={80}
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6 mb-3">
                        <div className="bgn-wf-field">
                          <input
                            placeholder="Email"
                            name="Email"
                            type="text"
                            maxLength={100}
                            defaultValue={""}
                            className="inputCustom w-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6 mb-3">
                        <div className="bgn-wf-field">
                          <input
                            placeholder="Phone"
                            name="Phone"
                            type="text"
                            maxLength={50}
                            defaultValue={""}
                            className="inputCustom w-100"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="bgn-wf-field">
                          <textarea
                            name="Description"
                            maxLength={32000}
                            className="inputCustom w-100"
                            defaultValue={""}
                            placeholder="Your Requirement Details"
                          />
                        </div>
                      </div>
                      <div className="mt-3" >
                        <div className="bgnwf-label" />
                        <div className="bgn-wf-field">
                        <input
                            id="formsubmit267113000000667005"
                            type="submit"
                            defaultValue="Submit"
                            className="btnSecondary me-3 mb-3"
                          />
                          <input
                            onclick="disableSubmitwhileReset267113000000667005()"
                            type="reset"
                            defaultValue="Reset"
                            className="btnPrimary"
                          />
                        </div>
                      </div>
                    </div>
                  </form> */}

                  <iframe width='610px' height='350px' src='https://bigin.zoho.in/crm/WebFormServeServlet?rid=e9cfc25d3a0c3f38a426576bf61dd1deb10c1c2513d84b56d089a801c9a8111dgid3fef1381d1076883454f9b8f5f77f1082ac431ee9953419b387a64de1fb423bc'></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
      <Footer />
    </>
  );
}
export default Contact;
